<template>
  <PageContentLayoutOnly :aria-label="$t('notes.title')" role="region">
    <b-tabs position="is-left" type="is-boxed" class="block whitecolor">
      <b-tab-item label="Property Notes">
        <br />

        <b-notification type="is-info is-light" has-icon aria-close-label="Close notification">
          Property Notes are related to the property itself, and will be shown regardless of the
          current owner of the property.
        </b-notification>

        <b-button
          type="is-primary"
          class="is-rounded"
          @click="openUnitNoteModal('add')"
          :style="{ marginBottom: '10px' }"
          >Add Property Note</b-button
        >

        <ModernTable
          unit-notes-table
          :region="unitNotesTable.region"
          :filters="unitNotesTable.filters"
          :rows="unitNotesTable.rows"
          :columns="unitNotesTable.columns"
          :pagination="{
            perPage: 10
          }"
          headerColor="#FFF"
          condensed
          readMoreColumn="Note"
        />
      </b-tab-item>

      <b-tab-item label="Owner Notes">
        <br />

        <b-notification type="is-info is-light" has-icon aria-close-label="Close notification">
          Owner Notes are related to the specific owner being viewed. In the event of a turnover of
          the property to a new owner, the owner notes will follow the owner and will be visible in
          the previous owner's details.
        </b-notification>

        <b-button
          type="is-primary"
          class="is-rounded"
          @click="openOwnerNoteModal('add')"
          :style="{ marginBottom: '10px' }"
          >Add Owner Note</b-button
        >

        <ModernTable
          owner-notes-table
          :region="ownerNotesTable.region"
          :filters="ownerNotesTable.filters"
          :rows="ownerNotesTable.rows"
          :columns="ownerNotesTable.columns"
          :pagination="{
            perPage: 10
          }"
          headerColor="#FFF"
          condensed
          readMoreColumn="Note"
        />
      </b-tab-item>
    </b-tabs>

    <Modal :toggle.sync="unitNotesModal">
      <ValidationObserver ref="form">
        <transition name="fade" mode="out-in" appear>
          <form class="form">
            <fieldset>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="UnitNote"
                  label="Property Note"
                  maxlength="8000"
                  type="textarea"
                  vid="unitNote"
                  placeholder="Property Note"
                  spellcheck="true"
                  aria-label="Property Note"
                  rules="max:8000|required"
                  class="is-small"
                  v-model="selectedNote"
                />
                <button
                  v-if="action && action == 'add'"
                  @click.prevent="addUnitNote(selectedNote)"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Add
                </button>
                <button
                  v-if="action && action == 'update'"
                  @click.prevent="updateUnitNote(selectedNote, selectedID)"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Update
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>
    <Modal :toggle.sync="ownerNotesModal">
      <ValidationObserver ref="form2" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form2">
            <fieldset>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="OwnerNote"
                  label="Owner Note"
                  maxlength="8000"
                  type="textarea"
                  vid="ownerNote"
                  placeholder="Owner Note"
                  spellcheck="true"
                  aria-label="Owner Note"
                  rules="max:8000|required"
                  class="is-small"
                  v-model="selectedNote"
                />
                <button
                  v-if="action && action == 'add'"
                  type="submit"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Add
                </button>
                <button
                  v-if="action && action == 'update'"
                  @click.prevent="updateOwnerNote(selectedNote, selectedID)"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Update
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
import ValidInput from '@/components/inputs/ValidInput'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import kms from '@/services/kms'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    Modal,
    ModernTable,
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    // for the purpose of testing
    kms: {
      type: Object,
      default: () => {
        return kms
      }
    },
    isDebug: {
      type: Boolean,
      default: true
    }
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.unitID = this.$route.params.id
    this.ownerID = _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null)

    this.reload()

    if (this.isDebug == true) console.debug('mounted unitID=' + this.unitID)
  },

  watch,

  methods,

  i18n: {
    messages: {
      en: { notes: { title: 'Notes' } }
    }
  }
}
</script>

<style lang="scss">
.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
