var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"aria-label":_vm.$t('notes.title'),"role":"region"}},[_c('b-tabs',{staticClass:"block whitecolor",attrs:{"position":"is-left","type":"is-boxed"}},[_c('b-tab-item',{attrs:{"label":"Property Notes"}},[_c('br'),_c('b-notification',{attrs:{"type":"is-info is-light","has-icon":"","aria-close-label":"Close notification"}},[_vm._v(" Property Notes are related to the property itself, and will be shown regardless of the current owner of the property. ")]),_c('b-button',{staticClass:"is-rounded",style:({ marginBottom: '10px' }),attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openUnitNoteModal('add')}}},[_vm._v("Add Property Note")]),_c('ModernTable',{attrs:{"unit-notes-table":"","region":_vm.unitNotesTable.region,"filters":_vm.unitNotesTable.filters,"rows":_vm.unitNotesTable.rows,"columns":_vm.unitNotesTable.columns,"pagination":{
          perPage: 10
        },"headerColor":"#FFF","condensed":"","readMoreColumn":"Note"}})],1),_c('b-tab-item',{attrs:{"label":"Owner Notes"}},[_c('br'),_c('b-notification',{attrs:{"type":"is-info is-light","has-icon":"","aria-close-label":"Close notification"}},[_vm._v(" Owner Notes are related to the specific owner being viewed. In the event of a turnover of the property to a new owner, the owner notes will follow the owner and will be visible in the previous owner's details. ")]),_c('b-button',{staticClass:"is-rounded",style:({ marginBottom: '10px' }),attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openOwnerNoteModal('add')}}},[_vm._v("Add Owner Note")]),_c('ModernTable',{attrs:{"owner-notes-table":"","region":_vm.ownerNotesTable.region,"filters":_vm.ownerNotesTable.filters,"rows":_vm.ownerNotesTable.rows,"columns":_vm.ownerNotesTable.columns,"pagination":{
          perPage: 10
        },"headerColor":"#FFF","condensed":"","readMoreColumn":"Note"}})],1)],1),_c('Modal',{attrs:{"toggle":_vm.unitNotesModal},on:{"update:toggle":function($event){_vm.unitNotesModal=$event}}},[_c('ValidationObserver',{ref:"form"},[_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{staticClass:"form"},[_c('fieldset',[_c('div',{staticClass:"column is-12"},[_c('valid-input',{staticClass:"is-small",staticStyle:{"width":"50px !important"},attrs:{"name":"UnitNote","label":"Property Note","maxlength":"8000","type":"textarea","vid":"unitNote","placeholder":"Property Note","spellcheck":"true","aria-label":"Property Note","rules":"max:8000|required"},model:{value:(_vm.selectedNote),callback:function ($$v) {_vm.selectedNote=$$v},expression:"selectedNote"}}),(_vm.action && _vm.action == 'add')?_c('button',{staticClass:"button is-primary is-rounded",style:({ float: 'right' }),on:{"click":function($event){$event.preventDefault();return _vm.addUnitNote(_vm.selectedNote)}}},[_vm._v(" Add ")]):_vm._e(),(_vm.action && _vm.action == 'update')?_c('button',{staticClass:"button is-primary is-rounded",style:({ float: 'right' }),on:{"click":function($event){$event.preventDefault();return _vm.updateUnitNote(_vm.selectedNote, _vm.selectedID)}}},[_vm._v(" Update ")]):_vm._e()],1)]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1)])])],1)],1),_c('Modal',{attrs:{"toggle":_vm.ownerNotesModal},on:{"update:toggle":function($event){_vm.ownerNotesModal=$event}}},[_c('ValidationObserver',{ref:"form2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{staticClass:"form2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onFormSubmit)}}},[_c('fieldset',[_c('div',{staticClass:"column is-12"},[_c('valid-input',{staticClass:"is-small",staticStyle:{"width":"50px !important"},attrs:{"name":"OwnerNote","label":"Owner Note","maxlength":"8000","type":"textarea","vid":"ownerNote","placeholder":"Owner Note","spellcheck":"true","aria-label":"Owner Note","rules":"max:8000|required"},model:{value:(_vm.selectedNote),callback:function ($$v) {_vm.selectedNote=$$v},expression:"selectedNote"}}),(_vm.action && _vm.action == 'add')?_c('button',{staticClass:"button is-primary is-rounded",style:({ float: 'right' }),attrs:{"type":"submit"}},[_vm._v(" Add ")]):_vm._e(),(_vm.action && _vm.action == 'update')?_c('button',{staticClass:"button is-primary is-rounded",style:({ float: 'right' }),on:{"click":function($event){$event.preventDefault();return _vm.updateOwnerNote(_vm.selectedNote, _vm.selectedID)}}},[_vm._v(" Update ")]):_vm._e()],1)]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }