import moment from 'moment'

export const data = function() {
  return {
    loading: false,
    confirmModal: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    },
    selectedRow: null,
    unitNoteList: [{}],
    ownerNoteList: [{}],
    unitID: null,
    ownerID: null,
    selectedID: null,
    selectedNote: '',
    formShow: false,
    action: '',
    hasPermission: false,

    ownerNotesModal: false,
    ownerNotesTable: {
      region: {},
      filters: {
        show: false
      },
      rows: [],
      columns: [
        {
          field: 'author',

          label: 'Author',
          aria: 'Author',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'note',

          label: 'Note',
          aria: 'Note',
          width: '50%',
          sortable: true,
          searchable: true
        },
        {
          field: 'modificationDate',
          date: true,

          label: 'Last Modified',
          aria: 'Last Modified',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'actions',
          label: 'Actions',
          width: '15%'
        }
      ]
    },

    unitNotesModal: false,
    unitNotesTable: {
      region: {},
      filters: {
        show: false
      },
      rows: [],
      columns: [
        {
          field: 'author',

          label: 'Author',
          aria: 'Author',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'note',

          label: 'Note',
          aria: 'Note',
          width: '50%',
          sortable: true,
          searchable: true
        },
        {
          field: 'modificationDate',
          date: true,

          label: 'Last Modified',
          aria: 'Last Modified',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'actions',
          label: 'Actions',
          width: '15%'
        }
      ]
    }
  }
}
