<template>
  <div>
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      v-if="hasMatchingAspNetUser"
      @click="loadUpdateNote(note, ownerNoteID)"
    >
      Edit
    </button>
    &nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      v-if="hasMatchingAspNetUser"
      @click="confirmOwnerNoteDelete(note, ownerNoteID)"
    >
      Delete
    </button>
  </div>
</template>

<script>
export default {
  props: {
    hasMatchingAspNetUser: Boolean,
    loadUpdateNote: Function,
    confirmOwnerNoteDelete: Function,
    note: String,
    ownerNoteID: [Number, String]
  }
}
</script>
