import OwnerNoteActions from './../components/OwnerNoteActions'
import UnitNoteActions from './../components/UnitNoteActions'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'

export const watch = {
  hoaId() {
    this.reload()
  },
  ownerNoteList() {
    const dateFormat = 'LL'

    const ownerNoteList = _get(this, 'ownerNoteList', [])
    if (!Array.isArray(ownerNoteList)) {
      return
    }

    const rows = ownerNoteList.map(note => {
      const id = _get(note, 'ownerNoteID', '')
      const noteText = _get(note, 'note', '')

      return {
        author: _get(note, 'aspNetName', ''),
        note: noteText,
        modificationDate: {
          date: parseDate(_get(note, 'modifiedDate', ''), dateFormat),
          format: dateFormat
        },
        actions: {
          component: OwnerNoteActions,
          props: {
            hasMatchingAspNetUser: _get(note, 'hasMatchingAspNetUser', false),

            loadUpdateNote: (_note, _id) => {
              this.selectedID = _id
              this.selectedNote = _note
              this.action = 'update'
              this.ownerNotesModal = true
            },

            confirmOwnerNoteDelete: (_note, _id) => {
              this.$buefy.dialog.confirm({
                title: 'Deleting Owner Note',
                message: `Are you sure you want to <b>delete</b> this Owner Note, '${_note}'?`,
                confirmText: 'Delete Owner Note',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteOwnerNote(_id)
              })
            },

            note: noteText,
            ownerNoteID: id
          }
        }
      }
    })

    this.ownerNotesTable.rows = rows
  },
  unitNoteList() {
    const dateFormat = 'LL'

    const unitNoteList = _get(this, 'unitNoteList', [])
    if (!Array.isArray(unitNoteList)) {
      return
    }

    const rows = unitNoteList.map(note => {
      const id = _get(note, 'unitNoteID', '')
      const noteText = _get(note, 'note', '')

      return {
        author: _get(note, 'aspNetName', ''),
        note: noteText,
        modificationDate: {
          date: parseDate(_get(note, 'modifiedDate', ''), dateFormat),
          format: dateFormat
        },
        actions: {
          component: UnitNoteActions,
          props: {
            hasMatchingAspNetUser: _get(note, 'hasMatchingAspNetUser', false),

            loadUpdateNote: (_note, _id) => {
              this.selectedID = _id
              this.selectedNote = _note
              this.action = 'update'
              this.unitNotesModal = true
            },

            confirmUnitNoteDelete: (_note, _id) => {
              this.$buefy.dialog.confirm({
                title: 'Deleting Property Note',
                message: `Are you sure you want to <b>delete</b> this Property Note, '${_note}'?`,
                confirmText: 'Delete Property Note',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteUnitNote(_id)
              })
            },

            note: noteText,
            unitNoteID: id
          }
        }
      }
    })

    this.unitNotesTable.rows = rows
  }
}
